@import "counter";
// Color theme variables
$theme-primary: #0a2d59;
$theme-secondary: #0d639a;
$theme-tertiary: #071529;
$theme-highlight: #f2c54b;
$theme-offwhite: #f0f0f0;
$theme-gray: #e4e4e4;
$theme-darkgray: #d6dbdf;

$theme-text-normal: black;
$theme-text-gray: #515151;
$theme-text-white: white;

$theme-link-normal: $theme-secondary;
$theme-link-visited: purple;

$theme-code-text: black;
$theme-code-background: white;

// Sizes
$paragraph-vmargin: 1.5rem;
$section-vmargin-bot: 0.5rem;
$section-vmargin-top: 2rem + $paragraph-vmargin;

// Breakpoints
$bp-small: 600px;
$bp-medium: 900px;
$bp-large: 1200px;
$bp-extra-large: 1600px;

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

html {
  font-family: "Noto sans", sans-serif;
  font-size: 16px;
  line-height: 1.3;

  @media (min-width: 1920px) {
    font-size: 18px; } }

body {
  color: $theme-text-normal;
  background-color: $theme-primary;
  display: grid;

  @media (max-width: $bp-small) {
    grid-template-columns: 100%;
    grid-template-areas: "tabpanel" "content" "footer" "resume"; }

  @media (min-width: $bp-small) {
    grid-template-columns: 1fr 80ch 1fr;
    grid-template-areas: "tabpanel tabpanel tabpanel" "left-panel content right-panel" "footer footer footer" "resume resume resume"; } }

.panel {
  background-color: $theme-secondary; }

.tabpanel {
  background-color: $theme-primary;
  grid-area: tabpanel;
  text-align: center;
  color: $theme-text-white;

  h1 {
    margin: 0rem; }

  .navline {
    text-align: center;
    padding: 1rem 0rem 1rem 0rem;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block; }

    a:hover {
      background-color: $theme-highlight;
      color: black; }

    a {
      text-decoration: none;
      padding: 1rem 0.5rem 1rem 0.5rem;
      color: $theme-text-white; }

    .active {
      background-color: $theme-secondary; } }

  p {
    margin: 0.2rem 0rem 1rem 0rem;
    font-size: 0.75rem; } }

footer {
  grid-area: footer;
  background-color: $theme-primary;
  padding: 1rem;

  div {
    margin: auto;
    width: 5rem; } }

a {
  text-decoration: underline;
  color: $theme-link-normal;

  &:hover,
  &:focus {
    color: $theme-link-visited; }

  &:visited {
    color: $theme-link-visited; } }

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;

  div {
    a {
      display: block;
      margin: auto;
      text-align: center; }

    p {
      text-align: center; } }

  .contact-icon {
    height: 5rem;
    width: 5rem; } }

// Posts and pages
.page,
.post {
  margin-bottom: 8rem; }

// Posts displayed in post listings

// Article header contains the title, description and date
.article-header {
  margin-top: 2rem;
  margin-bottom: 2rem; }

#resume-grid-outer {
  grid-area: resume;
  display: grid;
  //background-image: url(assets/textures/drawing.svg);
  background-color: $theme-secondary;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "intro" "education" "anhs" "fullerton" "work-experience" "digitalscepter" "iso" "helpdesk" "freelance" "hire" "skills-header" "programming" "windows" "linux" "networking" "security" "homelab" "outro"; }
  @media (min-width: 640px) and (max-width: 1366px) {
    grid-template-columns: 15% 1fr 15%;
    grid-template-rows: auto;
    grid-template-areas: "intro intro intro" ". education ." ". anhs ." ". fullerton ." ". work-experience ." ". digitalscepter ." ". iso ." ". helpdesk ." ". freelance ." ". hire ." ". skills-header ." ". programming ." ". windows ." ". linux ." ". networking ." ". security ." ". homelab ." "outro outro outro"; }

  @media (min-width: 1366px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "intro intro intro intro" ". education education ." ". anhs fullerton ." ". work-experience work-experience ." " . digitalscepter digitalscepter ." " . iso iso ." " . helpdesk helpdesk ." " . freelance hire ." " . skills-header skills-header ." " . programming windows ." " . linux networking ." " . security . ." " . homelab homelab ." "outro outro outro outro"; }

  #intro {
    grid-area: intro;
    text-align: center;
    padding: 10rem 0rem 5rem 0rem;
    color: $theme-text-white;
    background-color: $theme-primary;

    h1 {
      display: inline-block;
      border-bottom-width: 1px;
      border-bottom-style: solid; }

    h2 {
      margin-top: 0rem;
      font-weight: 100; } }

  .card {
    background-color: $theme-offwhite;
    padding: 1rem 1rem 0rem 1rem;
    border-radius: 0.2rem;
    @media (max-width: 960px) {
      margin: 1.5rem 2rem 2rem 1.5rem; }

    @media (min-width: 960px) {
      margin: 1.5rem 1rem 2rem 1rem;

      box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.9); }

    ul {
      padding: 0rem 0rem 0rem 1rem; }

    li {
      margin: 0.25rem 0rem 0.25rem 0rem; }

    h2 {
      margin: 0.5rem 0rem 0.5rem 0rem;
      text-align: center; }

    h3 {
      margin-top: 0.5rem;
      text-align: center;
      font-weight: 300; } }

  .section-header {
    color: $theme-text-white;
    margin-top: 2rem;
    text-align: center; }

  #education {
    grid-area: education; }

  #anhs {
    grid-area: anhs; }

  #fullerton {
    grid-area: fullerton; }

  #work-experience {
    grid-area: work-experience; }

  #helpdesk {
    grid-area: helpdesk; }

  #iso {
    grid-area: iso; }

  #digitalscepter {
    grid-area: digitalscepter; }

  #freelance {
    grid-area: freelance; }

  #hire {
    grid-area: hire; }

  #skills-header {
    grid-area: skills-header; }

  #programming {
    grid-area: programming; }

  #windows {
    grid-area: windows; }

  #linux {
    grid-area: linux; }

  #networking {
    grid-area: networking; }

  #security {
    grid-area: security; }

  #homelab {
    grid-area: homelab; }

  #outro {
    grid-area: outro;
    text-align: center;
    padding: 5rem 0rem 5rem 0rem;
    color: $theme-text-white;
    background-color: $theme-primary;

    a {
      color: $theme-text-white;
      text-decoration: none;
      display: inline-block;
      border-bottom-width: 1px;
      border-bottom-style: solid; } } }

article.post > h3 {
  counter-increment: section;

  &:before {
    content: counter(section) "."; } }

// Typography
.content {
  grid-area: content;
  background-color: $theme-offwhite;
  padding: 0rem 1.5rem 0rem 1.5rem;
  counter-reset: section -1;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    color: $theme-text-normal;
    text-rendering: optimizeLegibility;
    margin: $section-vmargin-top 0rem $section-vmargin-bot 0rem; }

  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem; }

  h2 {
    font-size: 1.5rem; }

  h3 {
    font-size: 1.25rem; }

  h4,
  h5 {
    font-size: 1rem; }

  p {
    margin-top: 0rem;
    margin-bottom: $paragraph-vmargin; }

  p + ul {
    margin: 0rem 0rem $paragraph-vmargin 0rem; }

  .post-listing {
    margin-bottom: 2rem;

    img {
      width: 100%; }

    h4 {
      margin-bottom: 0rem;
      margin-top: 0rem; }

    .post-description,
    .post-dateline,
    .post-tags {
      font-size: 0.9rem;
      margin-top: 0rem;
      margin-bottom: 0.4rem;
      color: $theme-text-gray; }

    .post-description {
      line-height: 1rem;
      margin: 0.3rem 0rem 0.3rem 0rem;
      color: $theme-text-normal; }

    .post-tags {
      line-height: 1.5rem; }

    .post-tag {
      white-space: nowrap;
      display: inline-block;
      color: black;
      font-size: 0.8rem;
      border-radius: 4px;
      background-color: $theme-gray;
      padding: 0.1rem 0.3rem 0.1rem 1.2rem;
      margin: 0rem 0.3rem 0.3rem 0rem;
      background-image: url(assets/icons/octicons/tag.svg);
      background-repeat: no-repeat;
      background-position: 0.2rem 50%; } }

  .toc {
    border: 1px solid;
    background-color: white;
    padding: 0.5rem;

    h4 {
      margin-top: 0; }

    ol {
      list-style: none;
      padding: 0 0 0 0.5rem;
      counter-reset: toc-counter -1;
      margin: 0;

      li {
        counter-increment: toc-counter;
        margin-bottom: 0.5rem; }

      li::before {
        font-size: 1.2rem;
        content: counter(toc-counter) ".";
        margin-right: 0.5rem; } } }

  div.highlighter-rouge {
    border: 1px solid;
    padding: 0rem 0.25rem 0rem 0.25rem;
    margin: 0rem 0rem $paragraph-vmargin 0rem;
    color: $theme-code-text;
    background-color: $theme-code-background;
    white-space: nowrap;
    font-size: 0.9rem;

    & > div {
      padding: 0rem 0.5rem 0rem 0.5rem;
      margin: 0rem 0.25rem 0rem 0.25rem;
      overflow-x: auto; } }

  code.highlighter-rouge {
    padding: 0rem 0.25rem 0rem 0.25rem;
    color: $theme-code-text;
    background-color: $theme-code-background; }

  .diagram {
    border-style: solid;
    border-width: 1px;
    padding: 0.5rem;
    margin-bottom: $paragraph-vmargin;
    background-color: white;
    font-size: 0.8rem;

    img {
      width: 100%; }

    .caption {
      margin: 0rem; } }

  blockquote {
    margin: 1.2rem 2rem ($paragraph-vmargin + 0.2rem) 2rem;
    border-width: 2px 0;
    border-style: solid;
    border-color: $theme-text-gray;
    background-color: $theme-gray;
    position: relative;

    &:before {
      content: "“";
      font-size: 6rem;
      position: absolute;
      top: -2rem;
      left: -2.5rem; }

    &:after {
      content: "”";
      font-size: 6rem;
      position: absolute;
      top: calc(100% - 1.8rem);
      left: 101%; }

    p {
      margin: 0.5rem 1rem 0.5rem 1rem; }

    .blockquote-cite {
      text-align: right;
      margin: 0rem 1rem 0.5rem 0rem;
      &:before {
        content: "—"; } } }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: $paragraph-vmargin;

    th {
      background-color: $theme-darkgray;
      border: 1px solid; }

    td {
      padding-left: 0.4rem;
      border: 1px solid; } } }
