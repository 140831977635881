@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.counter-main {
  display: flex;
  align-items: center;
  height: 100vh;

  main {
    margin: auto;
    color: white;
    text-align: center;
    font-size: 2rem;
    font-family: 'Permanent Marker', cursive;
    #since-we-met {
      margin-bottom: 6rem; } } }
